/* 
prefix
c:background-color
s:font-size
b:font-weight
u:text-decoration: underline
i:transform: skewX(-20deg)（italic）

suffix
-n：Normal（デフォルト設定値）スタイルとしては設定しない。

*/
.c-n {
  background-color: white !important;
  color: black !important;
}

.c-r {
  background-color: red !important;
  color: white !important;
}

.c-b {
  background-color: blue !important;
  color: white !important;
}

.c-g {
  background-color: green !important;
  color: white !important;
}
.c-h {
  background-color: grey !important;
  color: white !important;
}
.c-a {
  background-color: rgb(255, 251, 211) !important;
  color: black !important;
}


.s-s {
  font-size: 10px !important;
}

.s-n {
  font-size: 13px !important;
}

.s-l {
  font-size: 24px !important;
}

.b-n {
  font-weight: normal !important;
}
.b-b {
  font-weight: bold !important;
}

.u-n {
  text-decoration: none !important;
}
.u-u {
  text-decoration: underline !important;
}

.i-n {
  transform: skewX(0deg) !important;
}
.i-i {
  transform: skewX(-20deg) !important;
}



